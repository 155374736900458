define('xhrhelpers',['domhelpers', 'edap'], function(domHelpers, edap) {
    'use strict';

    /**
     * Create a XMLHttpRequest
     *
     * @example
     *    var xhr = createXMLHttpRequest();
     *
     * @returns {Object} XMLHttpRequest
     */
    function createXMLHttpRequest() {
        return new XMLHttpRequest();
    }


    /**
     * this.xhr -- XMLHttpRequest
     * this.get() -- the getter function for this.xhr
     * this.set() -- the getter function for this.xhr
     * this.setRequestHeader() -- set the request header of xhr
     * this.open() -- Open the XHR
     * this.createXHR() -- create the request, set the onload, onerror, and ontimeout function
     *
     *
     * @returns {XhrHelpers}
     * @constructor
     */
    function XhrHelpers() {
        /**
         *  Getter for an xhr property or the entire xhr
         *
         *
         *  @example
         *    // gets the entire xhr
         *    var xhr = xhr.get();
         *    // gets the attribute of the xhr
         *    var xhr = xhr.get('responseText');
         *
         *  @returns {Object} - Returns the this value || {String} - the corresponding value for key
         */

        this.get = function(key) {
            if (typeof key === 'undefined') {
                return this.xhr;
            }
            return this.xhr[key];
        };


        /**
         *  Setter for an xhr property
         *
         *  @param {String} [key] - xhr key name to set
         *  @param {Object|String|Number|Function} [value] - xhr value to set
         *  @returns {Boolean} - Returns if the value was set or not
         */
        this.set = function(key, value) {
            if (typeof key !== 'string') {
                edap.error('Attempting to set xhr key with non-strings:' + key);
                return false;
            }

            this.xhr[key] = value;
            return true;
        };

        /**
         * Setter for request header
         *
         * @param {String} [header] - header to set
         * @param {String} [value] - header value to set
         * @returns {boolean} - Returns if the header was set or not
         *
         */
        this.setRequestHeader = function(header, value) {
            if (typeof header !== 'string' || typeof value !== 'string') {
                edap.error('Attempting to set the header with non strings');
                return false;
            }
            this.xhr.setRequestHeader(header, value);
            return true;
        };

        /**
         *  Determines if a key is set in the xhr
         *
         *  @param {String} [key] - The xhr key name to check
         *  @returns {Boolean} - Returns if the value is set or not
         */
        this.isSet = function(key) {
            return (this.xhr[key] !== undefined && this.xhr[key] !== null);
        };

        /**
         *  Open the XHR
         *
         *  @param {String} [method] - The xhr method e.g. GET/POST/DELETE
         *  @param {String} [url] - The xhr url The URL to send the request to
         *  @returns {Boolean} - true if it successfully opened the XHR
         *                       false if it fails paremeter validation
         */
        this.open = function(method, url) {
            if (typeof method !== 'string' || typeof url !== 'string') {
                edap.error('Attempting to open the request with non strings');
                return false;
            }

            // Force Async since Synchronous AJAX is deprecated
            this.xhr.open(method, url, true);
            return true;
        };

        /**
         * Send the XHR
         */
        this.send = function() {
            this.xhr.send();
        };

        /**
         *
         * @param {String} - [method] - HTTP method GET/POST/PUT/DELETE
         * @param {String} - [url] - the url for AJAX request
         * @param {Function} - [onload] - the onload function for ajax
         * @param {Function} - [onerror] - the onerror function for ajax
         * @param {Function} - [ontimeout] - the timeout function for ajax
         * @returns {Boolean} - true if the XHR is created
         *                      false if the XHR is not created
         */
        this.createXHR = function(method, url, onload, onerror, ontimeout) {
            var that = this;

            function executeCallback(cb) {
                try {
                    cb(that);
                } catch (err) {
                    edap.error(err);
                }
            }

            if (this.xhr) {
                this.open(method, url);
            } else {
                edap.error('createXHR(): browser does not support XHRequest');
                return false;
            }

            this.set('onload', function() {
                executeCallback(onload);
            });

            this.set('onerror', function() {
                executeCallback(onerror);
            });

            this.set('ontimeout', function() {
                executeCallback(ontimeout);
            });

            return true;
        };

        /**
         *  Constructor
         */
        this.xhr = createXMLHttpRequest();

        return this;
    }

    return XhrHelpers;
});

