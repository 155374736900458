define('data-enrichment/inauth-helper',['domhelpers'], function(domHelpers) {
    'use strict';

    function InAuthHelper() {
        var cachedInAuthId = null,
            inAuthCookieName = 'IAID';

        /**
         * Gets the InAuth ID from cache or defaults to trying to find it via cookie
         *
         * @returns {String} - The InAuth ID
         */
        this.getId = function() {
            if (cachedInAuthId !== null) {
                return cachedInAuthId;
            }

            cachedInAuthId = domHelpers.getCookie(inAuthCookieName);

            return cachedInAuthId;
        };

        /**
         * Reset the cached InAuth Id
         */
        this.resetCache = function() {
            cachedInAuthId = null;
        };
    }

    return new InAuthHelper();
});

