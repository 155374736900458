define('ua/ecomm',['ua/ua-wrapper'], function(ua) {
    'use strict';

    var hasLoadedUAEcommPlugin = false,
        retObj = {};

    function checkRequredValues(data) {
        var requiredFields = [
                'productid',
                'productname',
                'productcategory',
                'productquantity',
                'productprice',
                // 'ordertax', not strictly required since we default to 0
                'ordertotal',
                'orderid',
                'paymentmethod'],
            i,
            val;

        for (i = 0; i < requiredFields.length; i++) {
            val = data[requiredFields[i]];

            if (val === undefined || val === null) {
                return false;
            }
        }

        return true;
    }

    function checkArraysEqualLength() {
        var arrs = arguments,
            i,
            len;

        // this is safe since this is a private function
        len = arrs[0].length;

        for (i = 0; i < arrs.length; i++) {
            if (arrs[i].length !== len) {
                return false;
            }
        }

        return true;
    }

    function loadPlugin() {
        if (!retObj.uaEcommPluginLoaded()) {
            ua.loadPlugin('ecommerce');
            hasLoadedUAEcommPlugin = true;
        }
    }

    retObj.trackEcommerce = function trackEcommerce(data) {
        var splitRegex = /[,;]/,
            productids,
            productnames,
            productcategories,
            productquantities,
            productprices,
            ordertax,
            ordertotal,
            orderid,
            paymentmethod,
            i;

        data = data || {};

        // set orderid if it is missing but another recognized value is present
        if (!data.orderid || data.orderid === '-1') {
            data.orderid = data.reservationid || data.bookingid;
        }

        // check required values
        if (!checkRequredValues(data)) {
            return null;
        }

        // now that we are good to fill in vars, do it
        productids = data.productid.split(splitRegex);
        productnames = data.productname.split(splitRegex);
        productcategories = data.productcategory.split(splitRegex);
        productquantities = data.productquantity.split(splitRegex);
        productprices = data.productprice.split(splitRegex);
        ordertax = typeof data.ordertax !== 'undefined' ? data.ordertax : 0;
        ordertotal = data.ordertotal;
        orderid = data.orderid;
        paymentmethod = data.paymentmethod;

        // check that arrays are all same length
        if (!checkArraysEqualLength(productids,
                productnames,
                productcategories,
                productquantities,
                productprices)) {
            return null;
        }

        loadPlugin();

        // make sure we start with a clean slate
        ua.clearEcommCart();

        ua.addEcommTransaction(orderid, paymentmethod, ordertotal, undefined, ordertax);

        for (i = 0; i < productids.length; i++) {
            ua.addEcommItem(orderid, productnames[i], productids[i],
                productcategories[i], productprices[i], productquantities[i]);
        }

        // returns a Doneable so pass that through
        return ua.sendEcomm();
    };

    retObj.uaEcommPluginLoaded = function uaEcommPluginLoaded() {
        return hasLoadedUAEcommPlugin;
    };

    return retObj;
});

