define('hadoop/listeners/user-event-handlers',['adlhelpers', 'domhelpers', 'doneable'], function(ADL, domHelpers, Doneable) {
    'use strict';

    /**
     * Only sets a key in an object if the value is set
     *
     * @returns {Object} - edap
     */
    function setPropertyIfValid(obj, key, val) {
        if (val) {
            obj[key] = val;
        }
    }

    /**
     * Processes a 'user.email.entered' event and send it to Hadoop
     *
     * @throws {TypeError} - if paremeters do not pass validation
     * @param {Object} [hadoop] - Instance of hadoop-wrapper
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     * @param {ADL} [adl] - Instance of an ADL model
     * @returns {Doneable} - resolved when the Hadoop send() is complete, or if there was an error
     */
    function processUserEmailEntered(hadoop, edap, adl) {
        var err;

        if (!edap || typeof edap.error !== 'function') {
            err = new TypeError('processUserEmailEntered(): must pass in an EDAP instance');
            throw err;
        }

        return new Doneable(function(done) {
            var sendData;

            if (!hadoop) {
                err = new TypeError('processUserEmailEntered(): hadoop must be set');
                throw err;
            }

            if (!(adl instanceof ADL)) {
                err = new TypeError('processUserEmailEntered(): adl must be an ADLHelpers instance');
                throw err;
            }

            // We should start with the entire ADL
            sendData = adl.get();

            // Due to an existing contract we need to duplicate data and store them under different key names
            sendData.pagehref = domHelpers.getHref();
            setPropertyIfValid(sendData, 'brand', adl.get('monikerbrand'));
            setPropertyIfValid(sendData, 'action', adl.get('eventaction'));
            setPropertyIfValid(sendData, 'startdate', adl.get('eventstartdate'));
            setPropertyIfValid(sendData, 'enddate', adl.get('eventenddate'));
            setPropertyIfValid(sendData, 'adults', adl.get('eventnumadults'));
            setPropertyIfValid(sendData, 'children', adl.get('eventnumchildren'));
            setPropertyIfValid(sendData, 'pets', adl.get('eventpets'));

            hadoop.send('user.email.entered', sendData, adl.isSetGet('sessionid'), adl.isSetGet('visitorid')).then(done);
        }, {
            error: edap.error
        });
    }

    return {
        processUserEmailEntered: processUserEmailEntered
    };
});

