define('ua/listeners/traveler-conversion',[
    'ua/ua-wrapper',
    'ua/ecomm',
    'ua/ua-config',
    'doneable',
    'adlhelpers'
], function(ua, ecomm, config, Doneable, ADL) {
    'use strict';

    /**
     * EVENTS:
     *  inquiry.attempt
     *  inquiry.success
     *  reservation.request.submit
     *  payment.submit
     *  vas.submit
     *
     */
    function setupListeners(edap) {
        function getID(data) {
            return data.reservationid || data.bookingid || data.bookingId;
        }

        edap.on('inquiry.attempt', function(data, setAsync, done) {
            var newData = {},
                passedADL = new ADL(data),
                pageADL = new ADL();

            // Only fire the inquiry.success if we are on the inquiry confirmation.
            // Since this is a delayed command we need to check both the page's ADL and the passed ADL data to see
            // if we are on the correct page. This is a special case message that is purpose built to only be fired using
            // delayProcessing it should be safe to look at the page's ADL.
            if (pageADL.get('pagename') === 'inquiry confirmation') {
                setAsync();

                if (passedADL.isSet('eventlistingids')) {
                    newData.eventlistingids = passedADL.get('eventlistingids');
                }

                edap.trigger('inquiry.success', newData).then(done);
            }
        });

        edap.on('inquiry.success', function(data, setAsync, done) {
            var opts = {},
                adl = new ADL(data),
                listingids;

            setAsync();

            if (adl.isSet('eventlistingids')) {
                listingids = adl.get('eventlistingids');
            } else {
                listingids = adl.getListingIds();
            }

            // custom metric 2
            opts[ua.metricName(2)] = 1;

            Doneable.all([
                ua.sendInteractionPageview(
                    data,
                    config.staticVPVs['inquiry confirmation'],
                    ua.buildInteractionVpvPagetype(data.pagetype)
                ),
                ua.sendEvent(
                    'property inquiry',
                    listingids,
                    data.inquiryid,
                    undefined,
                    opts,
                    data
                )
            ]).then(done);
        });

        edap.on('reservation.request.submit', function(data, setAsync, done) {
            var opts = {};

            setAsync();

            // custom metric 8
            opts[ua.metricName(8)] = 1;

            Doneable.all([
                ua.sendInteractionPageview(
                    data,
                    config.staticVPVs['booking confirmation'],
                    ua.buildInteractionVpvPagetype(data.pagetype)
                ),
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'booking',
                    'confirm',
                    getID(data),
                    undefined,
                    opts,
                    data
                )
            ]).then(done);
        });

        edap.on('payment.submit', function(data, setAsync, done) {
            setAsync();

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'traveler payment',
                    'confirm',
                    getID(data),
                    undefined,
                    undefined,
                    data
                )
            ]).then(done);
        });

        edap.on('vas.submit', function(data, setAsync, done) {
            var opts = {};

            setAsync();

            opts[ua.metricName(4)] = 1;

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'vas cart',
                    'confirm',
                    undefined,
                    undefined,
                    opts,
                    data
                )
            ]).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

