define('demo/edap-demo-integration',['edap'], function(edap) {
    'use strict';

    var moduleName = 'demo',
        myEdap = new edap.Scope(moduleName);

    function init() {
        myEdap.on('whos.there', function() {
            console.log(moduleName + ' is ready and listening'); // eslint-disable-line no-console
        });

        return edap;
    }

    return {
        init: init
    };
});

