define('ua/edap-ua-integration',[
    'edap',
    'ua/ua-config',
    'ua/ua-wrapper',
    'ua/register-ua-listeners'
], function(edap, uaConfig, ua, listeners) {
    'use strict';

    var moduleName = uaConfig.edapScopeName,
        myEdap = new edap.Scope(moduleName);

    function init() {
        var inittedSuccessfully;

        // Before we load the UA script, check if the "_ga" cookie already exists.
        // This allows us to determine if this a user's first hit on the site.
        ua.checkIfNewUser();

        inittedSuccessfully = ua.init();

        // skip registering listeners if we are unable to set up UA successfully
        if (inittedSuccessfully) {
            listeners.setupListeners(myEdap);
        }

        return edap;
    }

    return {
        init: init
    };
});

