define('feature-flag/edap-feature-flag-integration',['domhelpers', 'edap', 'feature-flag/feature-flags'], function(domHelpers, edap, Features) {
    'use strict';

    var moduleName = 'features',
        myEdap = new edap.Scope(moduleName);

    /**
     * Sets up a listener to tell EDAP when to grab the feature flag bucketing info
     *
     * @returns {Object} - edap
     */
    function init() {
        myEdap.once('features.load', function(data, setAsync, done) {
            var features = myEdap.getData().features,
                edapOpts = myEdap.globalOptions, // EDAP guarantees that globalOptions is always an {}
                startLoad = domHelpers.getMsFromEpoch(),
                replayExecutionTime = null,
                hasLoadedFeatures = false,
                endLoad,
                startReplay;

            // This function is called when loading the feature flag data is done, one way or another
            function listenerComplete() {
                // If we haven't loaded the features yet then we do that now to make sure any onLoad()
                // callbacks are called
                if (!hasLoadedFeatures) {
                    myEdap['public'].features.load([]);
                }

                // trigger our "did.load" event for feature flags
                myEdap.trigger('features.did.load', {
                    loadtime: '' + (endLoad - startLoad),
                    replaytime: '' + replayExecutionTime,
                    loadstatus: features.data.status
                }).then(done);
            }

            setAsync();

            if (!(features instanceof Features)) {
                features = new Features(myEdap, {
                    endpointOrigin: edapOpts.endpointOrigin
                });
                myEdap.getData().features = features;
            }

            features.loadFeaturesData().then(function() {
                var hasFeatures = features.data.featureFlagList.length > 0;

                endLoad = domHelpers.getMsFromEpoch();

                if (features.data.status === features.state.LOADED && hasFeatures) {
                    myEdap['public'].features.load(features.data.featureFlagList);
                    hasLoadedFeatures = true;

                    startReplay = domHelpers.getMsFromEpoch();

                    myEdap['public'].playback.replay().then(function() {
                        replayExecutionTime = domHelpers.getMsFromEpoch() - startReplay;
                        listenerComplete();
                    });
                } else {
                    listenerComplete();
                }
            });
        });

        return edap;
    }

    return {
        init: init
    };
});

