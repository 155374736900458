define('ua/listeners/ppb',['ua/ua-wrapper'], function(ua) {
    'use strict';

    /**
     * EVENTS:
     *  ppb.signup.submit
     *  ppb.newproperty.submit
     *  ppb.renewal.submit
     *
     */
    function setupListeners(edap) {
        var category = 'ppb',
            label = 'completion';

        edap.on('ppb.signup.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'signup',
                label,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('ppb.newproperty.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'new property',
                label,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('ppb.renewal.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'renewal',
                label,
                undefined,
                undefined,
                data
            ).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

