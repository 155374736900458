define('ua/listeners/software',['ua/ua-wrapper'], function(ua) {
    'use strict';

    /*
     * COREUI-474
     *
     * EVENTS:
     *  software.search.submit
     *  software.provider.click
     */
    function setupListeners(edap) {
        var adl = edap.getDL();

        if (adl.pagetype === 'marketing' && adl.pagename === 'pmsc') {
            edap.on('software.search.submit', function(data) {
                ua.sendEvent('owner activity', 'software search', data.eventlabel || 'undefined');
            });

            edap.on('software.provider.click', function(data) {
                ua.sendEvent('owner activity', 'partner link click', data.eventlabel || 'undefined');
            });
        }
    }

    return {
        setupListeners: setupListeners
    };
});

