define('ua/listeners/generic',[
    'ua/ua-wrapper',
    'ua/ua-config',
    'doneable',
    'adlhelpers'
], function(ua, config, Doneable, ADL) {
    'use strict';

    /**
     * EVENTS:
     *  pageview
     *  primary.action.rendered
     *  generic.error
     *  generic.modal
     *  generic.event
     *  generic.click
     *  test.exposure
     *
     */
    function setupListeners(edap) {
        function filterHighFrequency(adl, cb, done) {
            if (adl.isSetGet('highfrequency') === 'true') {
                done();
            } else {
                cb().then(done);
            }
        }

        function filterSendToGa(adl, cb, done) {
            if (adl.isSetGet('sendtoga') === 'true') {
                cb().then(done);
            } else {
                done();
            }
        }

        edap.on('pageview', function(data, setAsync, done) {
            var opts = {};

            setAsync();

            if (data.pagetype === 'property') {
                // custom metric 1 - PDP page view
                opts[ua.metricName(1)] = 1;
            }

            ua.sendPageview(data, opts).then(done);
        });

        edap.on('primary.action.rendered', function(data, setAsync, done) {
            var category = 'performance timing',
                name = 'primary.action.rendered';

            setAsync();

            // if it is explicitly called out as *not* the initial page in a single-page app,
            // append " (spa)" to the name to differentiate it from normal full page loads
            if (data.initialpageload === 'false') {
                name = name + ' (spa)';
            }

            ua.sendTiming(
                category,
                name,
                data.eventtime,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('generic.error', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            filterHighFrequency(adl, function() {
                return Doneable.all([
                    ua.sendException(adl.get('logmessage')),
                    ua.sendEvent(
                        'error',
                        adl.get('appname'),
                        adl.get('logmessage'),
                        undefined,
                        undefined,
                        data
                    )
                ]);
            }, done);
        });

        edap.on('generic.modal', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            filterHighFrequency(adl, function() {
                var shouldSendVPV = false,
                    pageurl,
                    pagetype;

                // supports open events for inquiry and booking modals
                if (adl.get('modalaction') === 'open') {
                    if (adl.get('modalname') === 'inquiry') {
                        pageurl = config.staticVPVs.inquirymodal;
                        shouldSendVPV = true;
                    } else if (adl.get('modalname') === 'booking') {
                        pageurl = config.staticVPVs.bookingmodal;
                        shouldSendVPV = true;
                    }
                }

                if (shouldSendVPV) {
                    pagetype = [ // e.g. "property (inquiry modal)"
                        adl.get('pagetype'),
                        ' (',
                        adl.get('modalname'),
                        ' modal)'
                    ].join('');

                    return ua.sendInteractionPageview(data, pageurl, pagetype);
                }
                return new Doneable();
            }, done);
        });

        edap.on('generic.event', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            filterHighFrequency(adl, function() {
                return ua.sendEvent(
                    adl.get('eventcategory'),
                    adl.get('eventaction'),
                    adl.get('eventlabel'),
                    adl.get('eventvalue'),
                    undefined,
                    data
                );
            }, done);
        });

        edap.on('generic.click', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            filterHighFrequency(adl, function() {
                return ua.sendEvent(
                    adl.get('eventcategory'),
                    adl.get('eventaction') + ' click',
                    adl.get('eventlabel'),
                    adl.get('eventvalue'),
                    undefined,
                    data
                );
            }, done);
        });

        edap.on('test.exposure', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            filterSendToGa(adl, function() {
                return ua.sendEvent(
                    'ab_testing',
                    'test exposure',
                    data.testname + ':' + data.variant
                );
            }, done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

