define('tagmanager/edap-tagmanager-integration',[
    'edap',
    'tagmanager/tmshelpers'
], function(edap, tmsHelpers) {
    'use strict';

    var moduleName = 'tagmanager',
        myEdap = new edap.Scope(moduleName);

    /**
     * Sets up the tag manager integration
     *
     * @return {Object} edap
     */
    function init() {
        tmsHelpers.init(myEdap);

        return edap;
    }

    return {
        init: init
    };
});

