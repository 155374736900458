define('ua/listeners/identity',[
    'ua/ua-wrapper',
    'adlhelpers'
], function(ua, ADL) {
    'use strict';

    /*
     * EVENTS:
     *
     * user.account.created
     * login.attempt
     * login.success
     * login.failure
     *
     */
    function setupListeners(edap) {
        var loginStr = 'login';

        function loginEventCallback(data, setAsync, done) {
            var adl = new ADL(data),
                defaultStr = '-1',
                logintype = adl.get('logintype') || defaultStr,
                loginsource = adl.get('loginsource') || defaultStr;

            setAsync();

            ua.sendEvent(
                loginStr,
                adl.get('edapeventname').replace('.', ' '),
                logintype + ':' + loginsource,
                undefined,
                undefined,
                data
            ).then(done);
        }

        edap.on('user.account.created', function(data, setAsync, done) {
            var adl = new ADL(data),
                unknownStr = 'unknown',
                defaultStr = 'default',
                userType = adl.isSetGet('newusertype') || unknownStr,
                stubOrFullVals = {
                    true: 'stub account',
                    false: 'full account',
                    default: unknownStr
                },
                marketingOptOutVals = {
                    true: 'remarketing opt-out',
                    false: 'no remarketing opt-out',
                    default: 'remarketing opt-out not presented'
                },
                stubOrFull,
                marketingOptOut;

            stubOrFull = stubOrFullVals[(adl.isSetGet('stubaccount') || defaultStr)];
            marketingOptOut = marketingOptOutVals[(adl.isSetGet('marketingoptout') || defaultStr)];

            if (userType === unknownStr) {
                edap.error('\'user.account.created\' triggered without \'newusertype\' property set');
            }

            setAsync();

            ua.sendEvent(
                'account creation',
                userType,
                [stubOrFull, marketingOptOut].join(':'),
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('login.attempt', loginEventCallback);

        edap.on('login.success', loginEventCallback);

        edap.on('login.failure', loginEventCallback);
    }

    return {
        setupListeners: setupListeners
    };
});

