define('ua/listeners/flsp',['ua/ua-wrapper'], function(ua) {
    'use strict';

    /**
     * EVENTS:
     *  featuredlisting.click
     *  similarproperty.click
     *
     */
    function setupListeners(edap) {
        edap.on('featuredlisting.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'merchandising',
                'featured listing',
                data.eventlistingids,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('similarproperty.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'merchandising',
                'similar property',
                data.eventlistingids,
                undefined,
                undefined,
                data
            ).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

