define('ua/listeners/social',[
    'ua/ua-wrapper',
    'domhelpers',
    'doneable',
    'adlhelpers'
], function(ua, domhelpers, Doneable, ADL) {
    'use strict';

    /**
     * EVENTS:
     *  social.list.reveal
     *  social.facebook.like
     *  social.facebook.share
     *  social.google.plus1
     *  social.pinterest.pin
     *  social.twitter.tweet
     *  social.email.click
     *  social.email.sent
     *
     */
    function setupListeners(edap) {
        var cat = 'social';

        /**
         * Gets the label to use for social events
         *
         * @param  {Object} adl The ADL object for the event's data
         * @return {String}     The event label to use
         */
        function getSocialEventLabel(adl) {
            // for historical reasons the property page gets the listing ID sent
            if (adl.get('pagetype') === 'property') {
                return adl.getListingIds();
            }

            return domhelpers.getHref();
        }

        edap.on('social.list.reveal', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent('traveler activity', 'share clicked', adl.getPublicUUID()).then(done);
        });

        edap.on('social.email.click', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent('traveler activity', 'share email clicked', adl.getPublicUUID()).then(done);
        });

        edap.on('social.email.sent', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent('traveler activity', 'share email sent', adl.getPublicUUID()).then(done);
        });

        edap.on('social.facebook.like', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            if (data.actionstate === 'on') {
                Doneable.all([
                    ua.sendEvent(cat, 'facebook like', getSocialEventLabel(adl)),
                    ua.sendSocial('facebook', 'like', domhelpers.getHref())
                ]).then(done);
            } else if (data.actionstate === 'off') {
                Doneable.all([
                    ua.sendEvent(cat, 'facebook unlike', getSocialEventLabel(adl)),
                    ua.sendSocial('facebook', 'unlike', domhelpers.getHref())
                ]).then(done);
            }
        });

        edap.on('social.facebook.share', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            Doneable.all([
                ua.sendEvent(cat, 'facebook share', getSocialEventLabel(adl)),
                ua.sendSocial('facebook', 'share', domhelpers.getHref())
            ]).then(done);
        });

        edap.on('social.google.plus1', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            Doneable.all([
                ua.sendEvent(cat, 'google plus', getSocialEventLabel(adl)),
                ua.sendSocial('google', 'plus1', domhelpers.getHref())
            ]).then(done);
        });

        edap.on('social.pinterest.pin', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            Doneable.all([
                ua.sendEvent(cat, 'pinterest', getSocialEventLabel(adl)),
                ua.sendSocial('pinterest', 'pin', domhelpers.getHref())
            ]).then(done);
        });

        edap.on('social.twitter.tweet', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            Doneable.all([
                ua.sendEvent(cat, 'tweet', getSocialEventLabel(adl)),
                ua.sendSocial('twitter', 'tweet', domhelpers.getHref())
            ]).then(done);
        });

        edap.on('social.linkedin.share', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            Doneable.all([
                ua.sendEvent(cat, 'linkedin share', getSocialEventLabel(adl)),
                ua.sendSocial('linkedin', 'share', domhelpers.getHref())
            ]).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

