define('ua/listeners/cart',[
    'ua/ua-wrapper',
    'ua/ecomm',
    'ua/ua-config',
    'doneable',
    'adlhelpers'
], function(ua, ecomm, config, Doneable, ADL) {
    'use strict';

    /**
     * EVENTS:
     *  owner.registration.submit
     *  cart.signup.submit
     *  cart.newsubscription.submit
     *  cart.addto.submit
     *  cart.renew.submit
     *  cart.specialoffer.submit
     *
     */
    function setupListeners(edap) {
        var confStr = 'confirm';

        edap.on('owner.registration.submit', function(data, setAsync, done) {
            var vpv = config.staticVPVs['owner registration'],
                opts = {},
                adl = new ADL(data);

            setAsync();

            // custom metric 11
            opts[ua.metricName(11)] = 1;

            Doneable.all([
                ua.sendInteractionPageview(
                    data,
                    vpv,
                    ua.buildInteractionVpvPagetype(data.pagetype)
                ),
                ua.sendEvent(
                    'registration',
                    'owner registration',
                    adl.getPublicUUID(),
                    undefined,
                    opts,
                    data
                )
            ]).then(done);
        });

        edap.on('cart.signup.submit', function(data, setAsync, done) {
            var opts = {};

            setAsync();

            // custom metric 12
            opts[ua.metricName(12)] = 1;

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'signup cart',
                    confStr,
                    data.orderid,
                    undefined,
                    opts,
                    data
                )
            ]).then(done);
        });

        edap.on('cart.newsubscription.submit', function(data, setAsync, done) {
            var opts = {};

            setAsync();

            // custom metric 12
            opts[ua.metricName(12)] = 1;

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'newproperty cart',
                    confStr,
                    data.orderid,
                    undefined,
                    opts,
                    data
                )
            ]).then(done);
        });

        edap.on('cart.addto.submit', function(data, setAsync, done) {
            setAsync();

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'addto cart',
                    confStr,
                    data.orderid,
                    undefined,
                    undefined,
                    data
                )
            ]).then(done);
        });

        edap.on('cart.renew.submit', function(data, setAsync, done) {
            setAsync();

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'renewal cart',
                    confStr,
                    data.orderid,
                    undefined,
                    undefined,
                    data
                )
            ]).then(done);
        });

        edap.on('cart.specialoffer.submit', function(data, setAsync, done) {
            setAsync();

            Doneable.all([
                ecomm.trackEcommerce(data),
                ua.sendEvent(
                    'specialoffer cart',
                    confStr,
                    data.orderid,
                    undefined,
                    undefined,
                    data
                )
            ]).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

