define('ua/listeners/traveler-activity',['ua/ua-wrapper', 'ua/ua-config', 'adlhelpers', 'doneable'], function(ua, config, ADL, Doneable) {
    'use strict';

    /**
     * EVENTS:
     *  datepicker.open
     *  datepicker.dates.selected
     *  datepicker.dates.cleared
     *  listing.compare.toggle
     *  listing.compare.submit
     *  listing.favorite.click
     *  listing.favorite.toggle
     *  listing.infotab.click
     *  listing.phone.reveal
     *  favoritelist.create.submit
     *  favoritelist.email.share
     *  review.submit
     *  traveler.review.submit
     *  listing.review.submit
     *  conversation.message.send
     *  reservation.cancellation.request.submit
     *  map.pin.click
     *
     */
    function setupListeners(edap) {
        edap.on('datepicker.open', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'datepicker',
                'open',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('datepicker.dates.selected', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'datepicker',
                'dates change',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('datepicker.dates.cleared', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'datepicker',
                'dates clear',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('listing.compare.toggle', function(data, setAsync, done) {
            setAsync();

            if (data.actionstate === 'on') {
                ua.sendEvent('compare', 'checkbox').then(done);
            } else if (data.actionstate === 'off') {
                ua.sendEvent('compare', 'uncheckbox').then(done);
            }
        });

        edap.on('listing.compare.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent('compare', 'submit').then(done);
        });

        edap.on('listing.favorite.click', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent('favorite', 'favorite clicked', adl.getPublicUUID()).then(done);
        });

        edap.on('listing.favorite.toggle', function(data, setAsync, done) {
            var adl = new ADL(data),
                puuid = adl.getPublicUUID();

            setAsync();

            if (data.actionstate === 'on') {
                ua.sendEvent('favorite', 'favorite created', puuid).then(done);
            } else if (data.actionstate === 'off') {
                ua.sendEvent('favorite', 'favorite removed', puuid).then(done);
            }
        });

        edap.on('hearting.heart', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'hearting',
                'hearted',
                undefined,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('hearting.unheart', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'hearting',
                'unhearted',
                undefined,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('listing.infotab.click', function(data, setAsync, done) {
            var pt = data.pagetype;

            setAsync();

            ua.sendEvent(pt + ' page tabs', data.tabname).then(done);
        });

        edap.on('listing.booknow.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'pdp page',
                'booking button click',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('listing.phone.reveal', function(data, setAsync, done) {
            var opts = {},
                adl = new ADL(data);

            setAsync();

            // custom metric 9
            opts[ua.metricName(9)] = 1;

            ua.sendEvent(
                'show phone',
                'click to reveal',
                adl.getListingIds(),
                undefined,
                opts
            ).then(done);
        });

        edap.on('favoritelist.create.submit', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent('favorite', 'favorite list created', adl.getPublicUUID()).then(done);
        });

        edap.on('favoritelist.email.share', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent('favorite', 'favorite list shared', adl.getPublicUUID()).then(done);
        });

        // DEPRECATED
        edap.on('review.submit', function(data, setAsync, done) {
            setAsync();

            Doneable.all([
                ua.sendInteractionPageview(
                    data,
                    config.staticVPVs.reviewsubmit,
                    ua.buildInteractionVpvPagetype(data.pagetype)
                ),
                ua.sendEvent(
                    'traveler review',
                    'traveler submitted'
                )
            ]).then(done);
        });

        edap.on('traveler.review.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent('reviews', 'review for traveler submitted').then(done);
        });

        edap.on('listing.review.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent('reviews', 'review for property submitted').then(done);
        });

        edap.on('conversation.message.send', function(data, setAsync, done) {
            // don't want to send traveler event for owner dashboard
            // TECHDEBT: this should probably be combined with the handler from rm.js
            if (data.pagetype === 'travelerdash') {
                setAsync();

                ua.sendEvent('traveler activity', 'inbox message sent').then(done);
            }
        });

        edap.on('reservation.cancellation.request.submit', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            ua.sendEvent(
                'traveler activity',
                'cancellation request sent',
                adl.get('cancellationreason'),
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('map.pin.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'traveler activity',
                'map pin clicked',
                data.pintype,
                undefined,
                undefined,
                data
            ).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

