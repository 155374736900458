define('ua/listeners/rm',['ua/ua-wrapper', 'adlhelpers'], function(ua, ADL) {
    'use strict';

    /**
     * EVENTS:
     *  conversation.message.send
     *  quote.send
     *  payment.request.submit
     *  payment.refund.submit
     *  reservation.accept.submit
     *  reservation.decline.submit
     *  reservation.cancellation.submit
     *  payment.cancel.submit
     *  payment.resend.submit
     *  calendar.add.submit
     *  calendar.uptodate.submit
     *  reservation.edit.submit
     *  payment.markpaid.submit
     *  claim.damage.click
     *  claim.eclaim.click
     *
     */
    function setupListeners(edap) {
        var category = 'owner activity';

        function getId(data) {
            var adl = new ADL(data),
                retVal,
                reservationid = adl.get('reservationid'),
                inquiryid = adl.get('inquiryid');

            if (adl.shouldSet(reservationid)) {
                retVal = reservationid;
            } else if (adl.shouldSet(inquiryid)) {
                retVal = inquiryid;
            } else {
                retVal = '-1';
            }

            return retVal;
        }

        edap.on('conversation.message.send', function(data, setAsync, done) {
            // don't want to send owner event for traveler dashboard
            // TECHDEBT: this should probably be combined with the handler from traveler-activity.js
            if (data.pagetype === 'ownerdash') {
                setAsync();

                ua.sendEvent(category, 'message sent', getId(data)).then(done);
            }
        });

        edap.on('quote.send', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'send quote', data.inquiryid).then(done);
        });

        edap.on('payment.request.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'attach payment request', data.eventquoteid).then(done);
        });

        edap.on('payment.refund.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'refund payment', data.quotepaymentscheduleid).then(done);
        });

        edap.on('reservation.accept.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'accept booking request', data.reservationid).then(done);
        });

        edap.on('reservation.cancellation.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'cancellation sent',
                undefined,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('reservation.decline.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'decline booking request', data.reservationid).then(done);
        });

        edap.on('payment.cancel.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'cancel payment request', data.quotepaymentscheduleid).then(done);
        });

        edap.on('payment.resend.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'resend payment request', data.quotepaymentscheduleid).then(done);
        });

        edap.on('calendar.add.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'add to calendar', getId(data) + ',' + data.reservationstatus).then(done);
        });

        edap.on('calendar.uptodate.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'calendar up to date').then(done);
        });

        edap.on('reservation.edit.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'edit booking request').then(done);
        });

        edap.on('payment.markpaid.submit', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(category, 'mark as paid').then(done);
        });

        edap.on('payments.onboarding.submit', function(data, setAsync, done) {
            var adl = new ADL(data);

            setAsync();

            if (data.pagename === 'dash:settings:ownerpayments') {
                ua.sendEvent(category, 'onboard for offline payments', adl.getPublicUUID()).then(done);
            } else {
                done();
            }
        });

        edap.on('claim.damage.click', function(data, setAsync, done) {
            var adl = new ADL(data);

            if (adl.isSet('reservationid')) {
                setAsync();
                ua.sendEvent(category, 'claim damage', adl.get('reservationid')).then(done);
            } else {
                ua.uaEdap.error('claim.damage.click missing reservationid property', data);
            }
        });

        edap.on('claim.eclaim.click', function(data, setAsync, done) {
            var adl = new ADL(data);

            if (adl.isSet('reservationid')) {
                setAsync();
                ua.sendEvent(category, 'csa eclaims', adl.get('reservationid')).then(done);
            } else {
                ua.uaEdap.error('claim.eclaim.click missing reservationid property', data);
            }
        });
    }

    return {
        setupListeners: setupListeners
    };
});

