define('ua/listeners/user',['adlhelpers', 'ua/ua-wrapper'], function(ADL, ua) {
    'use strict';

    /*
     * COREUI-574
     *
     * EVENTS:
     *  user.sms.entered
     *
     */
    function setupListeners(edap) {
        edap.on('user.sms.entered', function(data) {
            var adl = new ADL(data);

            ua.sendEvent('phone', 'sms:enrollment', adl.get('eventlabel'));
        });
    }

    return {
        setupListeners: setupListeners
    };
});

