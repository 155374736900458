define('hadoop/edap-hadoop-integration',['edap', 'hadoop/register-hadoop-listeners'], function(edap, listeners) {
    'use strict';

    var moduleName = 'hadoop',
        myEdap = new edap.Scope(moduleName);

    /**
     *  Main init() for the hadoop integration. It kicks off registering all the listeners.
     *
     *  @returns {Object} - EDAP instance to make this chainable
     */
    function init() {
        listeners.setupListeners(myEdap);

        return edap;
    }

    return {
        init: init
    };
});

