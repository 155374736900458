define('feature-flag/feature-flags',['domhelpers', 'doneable', 'edapUnderscore', 'xhrhelpers'], function(domHelpers, Doneable, _, XHRHelper) {
    'use strict';

    /**
     * Feature flag model constructor.
     * Contains all the business logic for communicating with and parsing responses from edap-service.
     *
     * @constructor
     * @param {Object} [edap] - window.edap or an EDAP scope to be used internally
     * @param {Object} [config] - configuration options supported:
     *                           - {String} [endpointOrigin] - Origin of the edap-service endpoint, (origin automatically added if blank)
     *                           - {String} [endpointPath] - Path to the edap-service endpoint
     *                           - {Integer} [ajaxTimeout] - Max time, in ms, to wait for the AJAX response
     */
    function Features(edap, config) {
        var features = this,
            initErr;

        /**
         * Validate
         */

        if (!edap || !edap.hasOwnProperty('edapVersion')) {
            throw new TypeError('Features: must pass in EDAP when creating a new instance');
        }

        if (!(features instanceof Features)) {
            initErr = new TypeError('Features must be called with the "new" keyword');
            edap.error(initErr);
            throw initErr;
        }

        /**
         * Configuration Options
         */

        config = config || {};

        // Default config values
        features.config = _.defaults({}, config, {
            endpointOrigin: '',
            endpointPath: '/edap/service/v1/getInitData',
            ajaxTimeout: 3 * 1000 // 3 s
        });

        // Set this to a local property in case a consumer of EDAP-Integrations doesn't
        // have access to the Features model
        features.state = Features.state;

        // Init our data container when we load the data
        features.data = {
            featureFlagList: [],
            status: Features.state.INIT
        };


        /**
         * Parses the response object from the edap-service "responseText" string
         *
         * @private
         * @param {String} [responseText] - edap-service XHR "responseText" string
         * @return {Object} - edap-service response object, or an empty object if there's a parse error
         */
        features.getResponseJSON = function(responseText) {
            try {
                return JSON.parse(responseText);
            } catch (e) {
                edap.error('getResponseJSON(): Could not parse JSON responseText: ' + responseText);
            }

            return {};
        };


        /**
         * Parses the payload of a feature
         *
         * @private
         * @param {Object} [payload] - The payload string form a feature
         * @return {*} - The JSON.parse() value of payload, if it was a JSON.stringify()'d object
         *               If it doesn't JSON.parse() then it returns the passed in parameter
         */
        features.parsePayload = function(payload) {
            var parsePayload = payload;

            if (typeof payload === 'string') {
                try {
                    parsePayload = JSON.parse(payload);
                } catch (e) {
                    parsePayload = payload;
                }
            }

            return parsePayload;
        };


        /**
         * Parses the list of features and builds a list of FeatureFlag models
         *
         * @private
         * @param {Object} [responseJSON] - JSON response object from edap-service
         * @return {Array} - The list of FeatureFlag models populated with the edap-service features data
         */
        features.getFeatureFlagList = function(responseJSON) {
            var ffList = [],
                ff;

            if (typeof responseJSON.features !== 'object') {
                return ffList;
            }

            _.forOwn(responseJSON.features, function(data, key) {
                try {
                    ff = new edap['public'].FeatureFlag(edap, {
                        name: key,
                        value: data.bucketValue,
                        payload: features.parsePayload(data.payload)
                    });
                    ffList.push(ff);
                } catch (e) {
                    edap.error('getFeatureFlagList(): Could not parse entry ' + key + ': ' + JSON.stringify(data));
                }
            });

            return ffList;
        };


        /**
         * Executes the edap-service XHR and parses the return values.
         * All parsed data is stored in features.data.*. Currently the following properties are set:
         *  - {Array} [features.data.featureFlagList] - Contains the list of FeatureFlags models
         *
         * @public
         * @returns {Doneable} - Doneable that is resolved when the network request is complete. You need to check
         *                       features.data.status. It can be set to:
         *                        - Features.state.INIT - Default value that means loadFeaturesData() has not been called
         *                        - Features.state.PENDING - XHR request has been sent but no response has been parsed.
         *                        - Features.state.LOADED - XHR response received, validated and parsed, features.data.* is ready
         *                        - Features.state.BADRESPONSE - XHR response received but did not pass validation, features.data.* not set
         *                        - Features.state.ERROR - XHR threw an error, features.data.* not set
         *                        - Features.state.TIMEOUT - XHR timed out, features.data.* not set
         */
        features.loadFeaturesData = function() {
            return new Doneable(function(done) {
                var logName = 'loadFeaturesData',
                    url,
                    xhr,
                    err;

                // onload function for xhr object
                function onload(xhrHelper) {
                    var status = xhrHelper.get('status'),
                        responseJSON;

                    if (status === 200) {
                        responseJSON = features.getResponseJSON(xhrHelper.get('responseText'));

                        // Now get parse, build and store the data
                        features.data.featureFlagList = features.getFeatureFlagList(responseJSON);
                        features.data.status = Features.state.LOADED;
                    } else {
                        err = new Error([
                            logName + '():',
                            'got status',
                            status,
                            'on requesting',
                            url
                        ].join(' '));
                        edap.error(err);

                        features.data.status = Features.state.BADRESPONSE;
                    }

                    done();
                }

                function onerror() {
                    err = new Error([
                        logName + '():',
                        'network error attempting to reach',
                        url
                    ].join(' '));

                    edap.error(err);

                    features.data.status = Features.state.ERROR;

                    done();
                }

                function ontimeout() {
                    err = new Error([
                        logName + '():',
                        'network request timed out attempting to reach',
                        url
                    ].join(' '));

                    edap.error(err);

                    features.data.status = Features.state.TIMEOUT;

                    done();
                }

                url = encodeURI([
                    features.config.endpointOrigin,
                    features.config.endpointPath
                ].join(''));

                xhr = new XHRHelper();

                xhr.createXHR('GET', url, onload, onerror, ontimeout);
                xhr.set('timeout', features.ajaxTimeout);

                // Reset the data containers since we are requesting new data
                features.data.featureFlagList = [];
                features.data.status = Features.state.PENDING;

                xhr.send();
            }, {
                error: edap.error
            });
        };

        return features;
    }

    // Static ENUM of states of the Features model
    Features.state = {
        INIT: 'init',
        PENDING: 'pending',
        LOADED: 'loaded',
        BADRESPONSE: 'bad response',
        ERROR: 'error',
        TIMEOUT: 'timeout'
    };

    return Features;
});

