define('data-enrichment/hooks/global',[
    'adlhelpers',
    'domhelpers',
    'data-enrichment/pageid-helper',
    'data-enrichment/inauth-helper'
], function(ADL, domHelpers, pageIdHelper, inAuthHelper) {
    'use strict';

    function setupHooks(edap) {
        edap['public'].lifecycle.register('onEventDataExtraction', function(eventName, data, opts) {
            var adl = new ADL(data),
                setIfShouldSet = adl.setIfShouldSet; // for da minificationz

            // on pageviews, we should store the pageview ID so it's available as the previous pageview ID on the next page
            if (eventName === 'pageview') {
                pageIdHelper.storeCurrentPageviewId(adl);
            }

            setIfShouldSet.apply(adl, ['pagehref', domHelpers.getHref()]);
            setIfShouldSet.apply(adl, ['edapintegrationsversion', domHelpers.getEdapIntegrationsVersion()]);
            setIfShouldSet.apply(adl, ['clienttimestamp', domHelpers.getMsFromEpoch()]);
            setIfShouldSet.apply(adl, ['edapeventid', edap['public'].createUuid()]);
            setIfShouldSet.apply(adl, ['currentpageviewid', pageIdHelper.getCurrentPageviewId()]);
            setIfShouldSet.apply(adl, ['parentpageviewid', pageIdHelper.getParentPageviewId(adl)]);
            setIfShouldSet.apply(adl, ['inauthid', inAuthHelper.getId()]);
            setIfShouldSet.apply(adl, ['screenwidth', domHelpers.getScreenWidth()]);
            setIfShouldSet.apply(adl, ['screenheight', domHelpers.getScreenHeight()]);
            setIfShouldSet.apply(adl, ['viewportwidth', domHelpers.getViewportWidth()]);
            setIfShouldSet.apply(adl, ['viewportheight', domHelpers.getViewportHeight()]);
            setIfShouldSet.apply(adl, ['mqsize', domHelpers.getMQSize()]);
            setIfShouldSet.apply(adl, ['clienttype', 'web']);
            setIfShouldSet.apply(adl, ['referralchange', domHelpers.isCrossDomainReferral()]);
            setIfShouldSet.apply(adl, ['preferredlanguage', domHelpers.getPreferredLanguage()]);

            return [eventName, data, opts];
        });
    }

    return {
        setupHooks: setupHooks
    };
});

