define('hadoop/register-hadoop-listeners',['hadoop/listeners/featureflagged'], function() {
    'use strict';

    var listeners = arguments,
        i;

    /**
     *  Loops through all the listener dependencies and calls the "setupListener" functions
     *
     *  @param {Object} [edap] - The EDAP scope instance to initialize all the listeners with
     */
    function setupListeners(edap) {
        for (i = 0; i < listeners.length; i++) {
            listeners[i].setupListeners(edap);
        }
    }

    return {
        setupListeners: setupListeners
    };
});

