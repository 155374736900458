define('adlhelpers',['edap'], function(edap) {
    'use strict';

    function ADLHelpers(adlData) {
        /**
         *  Getter for an ADL property or the entire ADL
         *
         *  @example
         *    // gets a single ADL value
         *    var lid = adl.get('listingid');
         *
         *  @example
         *    // gets the entire ADL
         *    var adlData = adl.get();
         *
         *  @param {Object} [key] - String key name for a single key, or undefined for the entire ADL
         *  @returns {Object} - Returns the this.adl[key] value
         */
        this.get = function(key) {
            if (key === undefined) {
                return this.adl;
            }

            return this.adl[key];
        };


        /**
         * Getter for an ADL property that also checks if it is Set
         *
         * @param  {string} key The key to get a value format
         * @return {*}          The ADL value if it is set or undefined
         */
        this.isSetGet = function(key) {
            if (this.isSet(key)) {
                return this.get(key);
            }

            return undefined;
        };


        /**
         * Sets a property in the ADL if not already set and passes the shouldSet() checks
         *
         * @param  {String} key   The key to set a value for if not already set
         * @param  {*}      value The value to set if there isn't a value for key yet
         */
        this.setIfNotSet = function(key, value) {
            if (!this.isSet(key) && this.shouldSet(value)) {
                this.set(key, '' + value);
            }
        };


        /**
         * Sets a property in the ADL if it passes the shouldSet() checks
         *
         * @param  {String} key   The key to set a value for
         * @param  {*}      value The value to set
         */
        this.setIfShouldSet = function(key, value) {
            if (this.shouldSet(value)) {
                this.set(key, '' + value);
            }
        };


        /**
         *  Special getter for the 'publicuuid' value
         *
         *  @returns {Object} - Returns correct publicuuid value based on multiple values
         *  TECHDEBT: This function shouldn't exist, but until we use publicuuid everywhere we need a fallback
         */
        this.getPublicUUID = function() {
            if (this.isSet('publicuuid')) {
                return this.get('publicuuid');
            } else if (this.isSet('guid')) {
                return this.get('guid');
            }

            return '-1';
        };


        /**
         *  Special getter for the 'listingids' value
         *
         *  @returns {Object} - Returns correct listingids value based on multiple values
         *  TECHDEBT: This can be removed if/when we move everyone to ADL.listingids for flexibility
         */
        this.getListingIds = function() {
            if (this.isSet('eventlistingids')) {
                return this.get('eventlistingids');
            } else if (this.isSet('listingids')) {
                return this.get('listingids');
            } else if (this.isSet('listingid')) {
                return this.get('listingid');
            }

            return '-1';
        };


        /**
         *  Setter for an ADL property
         *
         *  @param {String} [key] - ADL key name to set
         *  @param {String} [value] - ADL value to set
         *  @returns {Boolean} - Returns if the value was set or not
         */
        this.set = function(key, value) {
            if (typeof key !== 'string' || typeof value !== 'string') {
                edap.error('Attempting to set ADL key/value with non-strings: ' + key + ', ' + value);
                return false;
            }

            this.adl[key] = value;
            return true;
        };


        /**
         *  Determines if a value is correct and "settable"
         *
         *  @param {String} [val] - The value to check
         *  @returns {Boolean} - Returns if the value is settable or not
         */
        this.shouldSet = function(val) {
            return (val !== undefined && val !== null && val !== '-1');
        };


        /**
         *  Determines if a key is set in the ADL
         *
         *  @param {String} [key] - The ADL key name to check
         *  @returns {Boolean} - Returns if the value is set or not
         */
        this.isSet = function(key) {
            return this.shouldSet(this.adl[key]);
        };


        /**
         *  Determines if an ADL value is Boolean/String true
         *
         *  @param {String} [key] - The ADL key name to check
         *  @returns {Boolean} - Returns if the value is true or not
         */
        this.isTrue = function(key) {
            return !!this.adl[key] && this.adl[key].toString && typeof this.adl[key].toString === 'function' && this.adl[key].toString() === 'true';
        };


        /**
         *  Determines if an ADL value is Boolean/String false
         *
         *  @param {String} [key] - The ADL key name to check
         *  @returns {Boolean} - Returns if the value is false or not
         */
        this.isFalse = function(key) {
            return this.adl[key] !== undefined && this.adl[key].toString && typeof this.adl[key].toString === 'function' && this.adl[key].toString() === 'false';
        };

        /**
         *  Constructor
         */

        // Useful to be able to pass the "ADL" in the case of EDAP event listeners
        if (adlData !== null && typeof adlData === 'object') {
            this.adl = adlData;

        // default to getting the global ADL from EDAP
        } else {
            this.adl = edap.getDL();
        }

        return this;
    }


    return ADLHelpers;
});

