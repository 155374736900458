define('debughelpers',['domhelpers'], function(domHelpers) {
    'use strict';

    var helpers = {

        /**
         * Gets the EDAP debug cookie name
         *
         * The cookie's value should be of the form
         *   "integrationName=true|otherIntegrationName=false|aThirdIntegrationName=true"
         *
         * @example "ua=true|hadoop=false"
         *
         * @return {String} The name of the EDAP debug cookie
         */
        getDebugCookieName: function() {
            return 'edapDebug';
        },

        /**
         * Determines whether a given integration has been cookied for debug module
         *
         * @param  {String} integrationName The name of the EDAP integration to check debug status form
         * @return {Boolean}                true if debug mode is enabled, false otherwise (disabled or no cookie found)
         */
        isInDebugMode: function(integrationName) {
            var debugCookieName = helpers.getDebugCookieName(),
                debugCookie = domHelpers.getCookie(debugCookieName) || '',
                searchString = integrationName + '=true';

            return debugCookie.indexOf(searchString) !== -1;
        }
    };

    return helpers;
});

