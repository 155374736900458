define('ua/listeners/component-interaction',['adlhelpers', 'ua/ua-wrapper'], function(ADL, ua) {
    'use strict';

    /**
     * EVENTS:
     *  page.module.view
     *  property.click
     *  refinement.click
     *  search.link.click
     *  destination.link.click
     */
    function setupListeners(edap) {
        var category = 'component interaction';

        edap.on('page.module.view', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'module view',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('property.tile.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'property tile click',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('refinement.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'refinement click',
                data.eventrefinements,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('search.link.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'search link click',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('destination.link.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'destination link click',
                [data.eventlbsuuid, data.actionlocation].join(':'),
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('region.link.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'region link click',
                [data.eventlbsuuid, data.actionlocation].join(':'),
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('recentactivity.continue.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'recentactivity continue click',
                data.eventlbsuuid,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('recentactivity.view', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'recentactivity view',
                data.eventsearchterm,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('recentactivity.viewall.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                category,
                'recentactivity viewall click',
                [data.eventrequestmarker, data.actionlocation].join(':'),
                undefined,
                undefined,
                data
            ).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

