define('ua/listeners/partner-conversion',[
    'ua/ua-wrapper',
    'adlhelpers'
], function(ua, ADL) {
    'use strict';

    /*
     * EVENTS:
     *
     * user.account.created
     * listing.onboarding.started
     * listing.onboarding.submit
     * owner.lyp.click
     *
     */
    function setupListeners(edap) {
        var unknownStr = 'unknown';

        edap.on('listing.onboarding.started', function(data, setAsync, done) {
            var adl = new ADL(data),
                newSupplierVals = {
                    true: 'new supplier',
                    false: 'existing supplier',
                    default: unknownStr
                },
                newSupplier;

            newSupplier = newSupplierVals[(adl.isSetGet('newsupplier') || 'default')];

            if (newSupplier === unknownStr) {
                edap.error('\'listing.onboarding.started\' triggered without \'newsupplier\' property set');
            }

            setAsync();

            ua.sendEvent(
                'supplier onboarding',
                'started',
                newSupplier,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('listing.onboarding.submit', function(data, setAsync, done) {
            var adl = new ADL(data),
                newSupplierVals = {
                    true: 'new supplier',
                    false: 'existing supplier',
                    default: unknownStr
                },
                newSupplier;

            newSupplier = newSupplierVals[(adl.isSetGet('newsupplier') || 'default')];

            if (newSupplier === unknownStr) {
                edap.error('\'listing.onboarding.submit\' triggered without \'newsupplier\' property set');
            }

            setAsync();

            ua.sendEvent(
                'supplier onboarding completion',
                newSupplier,
                undefined,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('owner.lyp.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'supplier onboarding',
                'lyp module clicked',
                data.actionlocation,
                undefined,
                undefined,
                data
            ).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

