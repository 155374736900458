define('data-enrichment/edap-data-enrichment-integration',[
    'edap',
    'data-enrichment/register-data-enrichment-hooks'
], function(edap, hooks) {
    'use strict';

    var moduleName = 'data-enrichment',
        myEdap = new edap.Scope(moduleName);

    /**
     *  Main init() for the hadoop integration. It kicks off registering all the hooks.
     *
     *  @returns {Object} - EDAP instance to make this chainable
     */
    function init() {
        hooks.setupHooks(myEdap);

        return edap;
    }

    return {
        init: init
    };
});

