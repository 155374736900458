define('ua/dimension-extractors',[
    'domhelpers',
    'adlhelpers'
], function(domHelpers, ADL) {
    'use strict';

    function Extractors() {
        /**
         *  Extracts the contents of the UA Entry Info cookie into an object
         *
         *  @example
         *    extractors.parseEntryInfoCookie(ua);
         *
         *  @param {Object} [ua] - UA-wrapper object
         *  @returns {Object} - Object of cookie data based on the uaConfig.entryInfoCookieFormat
         */
        this.parseEntryInfoCookie = function(ua) {
            var uaConfig = ua.getConfig(),
                cookieData = domHelpers.getCookie(uaConfig.entryInfoCookie),
                cookieFormat = uaConfig.entryInfoCookieFormat,
                retData = {},
                win = domHelpers.getWindow(),
                splitData,
                tmpVal,
                i,
                len;

            if (cookieData === null) {
                splitData = [];
            } else {
                splitData = cookieData.split(':');
            }

            for (i = 0, len = cookieFormat.length; i < len; i++) {
                tmpVal = splitData[i];

                if (tmpVal === undefined) {
                    tmpVal = '-1';
                }

                retData[cookieFormat[i]] = win.decodeURIComponent(tmpVal);
            }

            return retData;
        };


        /**
         *  Updates the UA Entry Info cookie. Has a hard coded 30 minutes (session) timeout
         *
         *  @example
         *    extractors.updateValueEntryInfoCookie(ua, {entrypagename: 'testpage'}, 'homeaway.com');
         *
         *  @param {Object} [ua] - UA-wrapper object
         *  @param {Object} [data] - Cookie data object to set based on updateValueEntryInfoCookie
         *  @param {Stirng} [hostname] - Hostname of cookie
         */
        this.updateValueEntryInfoCookie = function(ua, data, hostname) {
            var uaConfig = ua.getConfig(),
                cookieData = this.parseEntryInfoCookie(ua),
                cookieFormat = uaConfig.entryInfoCookieFormat,
                win = domHelpers.getWindow(),
                cookieArr = [],
                tmpVal,
                i,
                len;

            for (i = 0, len = cookieFormat.length; i < len; i++) {
                tmpVal = data[cookieFormat[i]];

                if (data[cookieFormat[i]] !== undefined) {
                    tmpVal = data[cookieFormat[i]];
                } else if (cookieData[cookieFormat[i]]) {
                    tmpVal = cookieData[cookieFormat[i]];
                }

                if (tmpVal === undefined) {
                    tmpVal = '-1';
                }

                cookieArr.push(win.encodeURIComponent(tmpVal));
            }

            domHelpers.setCookie(uaConfig.entryInfoCookie, cookieArr.join(':'), hostname, 30);
        };


        /**
         *  Extractor for the UA visitorid/clientid
         *
         *  @example
         *    var cid = extractors.getVisitorId(new ADL({}), ua, tracker);
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @param {Object} [ua] - UA-wrapper object
         *  @param {Object} [tracker] - UA Tracker
         *  @returns {String} - first segmenet of the UA clientid
         */
        this.getVisitorId = function(adl, ua, tracker) {
            var cid = tracker.get('clientId'),
                retval;

            if (cid) {
                retval = cid;
            } else {
                ua.uaEdap.error('could not get UA clientid, skipping...');
                retval = '-1';
            }

            return retval;
        };


        /**
         *  Extractor for the Entry Page Name
         *
         *  @example
         *    var ep = extractors.getEntryPageName(new ADL({}), ua, tracker);
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @param {Object} [ua] - UA-wrapper object
         *  @returns {String} - the entry page name
         */
        this.getEntryPageName = function(adl, ua) {
            var cookieData = this.parseEntryInfoCookie(ua),
                profileData = ua.getUAProfileData(),
                profileCookieDomain = profileData[1],
                ep;

            ep = cookieData.entrypagename;

            if (ep === '-1') {
                ep = adl.get('pagename');
                this.updateValueEntryInfoCookie(ua, {entrypagename: ep}, profileCookieDomain);
            } else {
                this.updateValueEntryInfoCookie(ua, {entrypagename: ep}, profileCookieDomain);
            }

            return ep;
        };


        /**
         *  Extractor for the Entry Page Type
         *
         *  @example
         *    var ept = extractors.getEntryPageType(new ADL({}), ua);
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @param {Object} [ua] - UA-wrapper object
         *  @returns {String} - the entry page type
         */
        this.getEntryPageType = function(adl, ua) {
            var cookieData = this.parseEntryInfoCookie(ua),
                profileData = ua.getUAProfileData(),
                profileCookieDomain = profileData[1],
                ept;

            ept = cookieData.entrypagetype;

            if (ept === '-1') {
                ept = adl.get('pagetype');
                this.updateValueEntryInfoCookie(ua, {entrypagetype: ept}, profileCookieDomain);
            } else {
                this.updateValueEntryInfoCookie(ua, {entrypagetype: ept}, profileCookieDomain);
            }

            return ept;
        };


        /**
         *  Extractor for Brand
         *
         *  @example
         *    var brand = extractors.getBrand(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - the brand
         */
        this.getBrand = function(adl) {
            if (adl.isSet('monikerbrand')) {
                return adl.get('monikerbrand');
            } else if (adl.isSet('brand')) {
                return adl.get('brand');
            }

            return '-1';
        };


        /**
         *  Extractor for the url
         *
         *  @example
         *    var href = extractors.getUrl(new ADL({}), ua);
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @param {Object} [ua] - UA-wrapper object
         *  @returns {String} - the url
         */
        this.getUrl = function(adl, ua) {
            var href = '-1';

            try {
                href = domHelpers.getPage();
            } catch (err) {
                ua.uaEdap.error('Error when extracting UA url: ' + err.message);
            } finally {
                return href;
            }
        };


        /**
         *  Extractor for the Search Term
         *
         *  @example
         *    var searchterm = extractors.getSearchTerm(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - Search term based on the ADL
         */
        this.getSearchTerm = function(adl) {
            if (adl.isSet('searchterm')) {
                return adl.get('searchterm');

            // TECHDEBT: GT sets ADl.keywords right now, should be removed when they set ADL.searchterm correctly
            } else if (adl.isSet('keywords')) {
                return adl.get('keywords');
            }

            return '-1';
        };


        /**
         *  Extractor for the "CalendarLastUpdated" value
         *
         *  @example
         *    var calendarlastupdated = extractors.getCalendarLastUpdated(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - "CalendarLastUpdated" value based on the ADL
         */
        this.getCalendarLastUpdated = function(adl) {
            if (adl.isSet('calendarlastupdated')) {
                return adl.get('calendarlastupdated');

            // TECHDEBT: GT sets ADl.calendarupdated right now, should be removed when they set ADL.calendarlastupdated correctly
            } else if (adl.isSet('calendarupdated')) {
                return adl.get('calendarupdated');
            }

            return '-1';
        };


        /**
         *  Extractor for the "getPDPEntryDate" value
         *
         *  @example
         *    var pdpentrydate = extractors.getPDPEntryDate(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - "getPDPEntryDate" value based on the ADL
         */
        this.getPDPEntryDate = function(adl) {
            var startDate,
                endDate;

            if (adl.get('pagetype') === 'property') {
                startDate = adl.get('startdate');
                endDate = adl.get('enddate');

                return ((typeof startDate === 'string' && startDate.length > 0) &&
                        (typeof endDate === 'string' && endDate.length > 0)) ? 'dates on PDP' : 'no dates';
            }

            return '-1';
        };


        /**
         *  Extractor for proctor logging (both the value to log and where to log it)
         *  Will return "-1" for the dimension and/or proctor string if the values aren't recognized/valid
         *  This will keep them from being written to any of the visit-scoped proctor custom dimensions
         *
         *  @example
         *    var proctorDataStruct = extractors.getProctorDimension(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {Array} - [{String} dimensionId, {String} proctorTestString]
         */
        this.getProctorDimension = function(adl) { // eslint-disable-line complexity
            // disabled eslint complexity rather than refactoring since sharding out proctor data is TECHDEBT
            var proctor = adl.get('proctor'),
                pageAdl = new ADL(),
                retData;

            if (proctor === undefined || proctor === null || proctor === '') {
                proctor = '-1';
            }

            // TECHDEBT: get pagetype from the page's ADL rather than the event data because of the overridden pagetype on VPVs
            switch (pageAdl.get('pagetype')) {
                case 'booking':
                case 'booking payment':
                    retData = ['48', proctor];
                    break;

                case 'cart':
                    retData = ['30', proctor];
                    break;

                case 'travelerdash':
                    retData = ['96', proctor];
                    break;

                case 'home':
                    retData = ['99', proctor];
                    break;

                case 'lyp':
                    retData = ['46', proctor];
                    break;

                case 'ownerdash':
                    if (adl.get('appname') === 'global-dashboard-ui') {
                        retData = ['44', proctor];
                    } else {
                        retData = ['28', proctor];
                    }
                    break;

                case 'search landing page':
                    if (adl.get('appname') === 'landingpageapp') {
                        retData = ['102', proctor];
                    } else {
                        // NOTE: node-slp will be getting on proctor at some point, don't want them clobbering landingpageapp
                        retData = ['-1', '-1'];
                    }
                    break;

                case 'search':
                case 'property':
                case 'inquiry':
                case 'inquiry confirmation': // TECHDEBT: This isn't in the spec but people use it ¯\_(ツ)_/¯
                    retData = ['38', proctor];
                    break;

                default:
                    retData = ['-1', '-1'];
            }

            return retData;
        };


        /**
         *  Extractor for ppc ids
         *
         *  @example
         *    var ppc = extractors.getPPC(new ADL({}), ua, tracker);
         *
         *  @returns {String} - PPC ID
         */
        this.getPPC = function() {
            var val = domHelpers.getSearchParam('k_clickid');

            return val || '-1';
        };


        /**
         *  Extractor for ICID vals
         *
         *  @example
         *    var icid = extractors.getICID(new ADL({}), ua, tracker);
         *
         *  @returns {String} - ICIC value
         */
        this.getICID = function() {
            var val = domHelpers.getSearchParam('icid');

            return val || '-1';
        };


        /**
         *  Extractor for the EDAP-Integrations Version
         *
         *  @example
         *    var eiVersion = extractors.getEDAPIntegrationsVersion(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - the EDAP-Integrations version or 'unknown'
         */
        this.getEDAPIntegrationsVersion = function(adl) {
            var version = domHelpers.getEdapIntegrationsVersion();

            if (adl.shouldSet(version)) {
                return version;
            }

            return 'unknown';
        };


        /**
         *  Extractor for the Trip Dates
         *
         *  @example
         *    var tripDates = extractors.getTripDates(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - the Trip Dates string in the format "ADL.eventstartdate,ADL.eventenddate"
         *                      or "ADL.startdate,ADL.enddate". If both values are missing, a "-1" is returned
         */
        this.getTripDates = function(adl) {
            var startDate = 'startdate',
                endDate = 'enddate',
                eventStartDate = 'event' + startDate,
                eventEndDate = 'event' + endDate,
                datesStr = '-1',
                dates;

            if (adl.isSet(eventStartDate) && adl.isSet(eventEndDate)) {
                dates = [
                    adl.get(eventStartDate),
                    adl.get(eventEndDate)
                ];
            } else if (adl.isSet(startDate) && adl.isSet(endDate)) {
                dates = [
                    adl.get(startDate),
                    adl.get(endDate)
                ];
            }

            if (dates) {
                datesStr = dates.join(',');
            }

            return datesStr;
        };


        /**
         * Gets the string for number of guests
         *
         * Format: numadults,numchildren,numinfants,withpets
         * Any of those values will be replaced by "-1" if missing
         *
         * @param  {Object} adl ADLHelper instance
         * @return {String}     The comma-delimited string for number of guests
         */
        this.getNumGuests = function(adl) {
            var defaultValue = '-1';

            function defaultToMinusOne(val) {
                if (val === undefined) {
                    return defaultValue;
                }
                return val;
            }

            return [
                defaultToMinusOne(adl.isSetGet('numadults')),
                defaultToMinusOne(adl.isSetGet('numchildren')),
                defaultToMinusOne(adl.isSetGet('numinfants')),
                defaultToMinusOne(adl.isSetGet('withpets'))
            ].join(',');
        };


        /**
         *  Abstract extractor that will return a value in the form:
         *      "ADL.minKey,ADL.maxKey"
         *   where missing values are set as a "-1" and if both values are missing then
         *   it returns a "-1"
         *
         *  @example
         *    var searchGuests = extractors.getRangeSet(new ADL({}), ua, 'minsleeps', 'maxsleeps');
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @param {Object} [ua] - UA-wrapper object
         *  @param {String} [minKey] - Minimum (left side) ADL key to lookup
         *  @param {String} [maxKey] - Maximum (right side) ADL key to lookup
         *  @returns {String} - comma-delimited list of "ADL.minKey,ADL.maxKey"
         *                      Missing values are set as a "-1", if both values are missing then this
         *                      will return "-1";
         */
        this.getRangeSet = function(adl, ua, minKey, maxKey) {
            var defaultValue = '-1',
                minVal,
                maxVal;

            if (!minKey || !maxKey) {
                ua.uaEdap.error('getRangeSet: Incorrect min/maxKey parameters: minKey:"' + minKey + '" maxKey:"' + maxKey + '"');
                return defaultValue;
            }

            // If either is set then we need to return a value
            if (adl.isSet(minKey) || adl.isSet(maxKey)) {
                minVal = (adl.isSetGet(minKey) === undefined ? defaultValue : adl.get(minKey));
                maxVal = (adl.isSetGet(maxKey) === undefined ? defaultValue : adl.get(maxKey));
                return minVal + ',' + maxVal;
            }

            return defaultValue;
        };


        /**
         * Gets the HomeAway visitor ID from the 'hav' cookie
         *
         * @return {String} The HA Visitor ID
         */
        this.getHaVisitorID = function() {
            return domHelpers.getCookie('hav') || '-1';
        };

        /**
         * Gets the HomeAway session ID from the 'has' cookie
         *
         * @return {String} The HA session ID
         */
        this.getHaSessionID = function() {
            return domHelpers.getCookie('has') || '-1';
        };

        /**
         *  Extractor for currency
         *
         *  @example
         *    var currency = extractors.getCurrency(new ADL({}));
         *
         *  @param {Object} [adl] - ADLHelper instance
         *  @returns {String} - The currency code
         */
        this.getCurrency = function(adl) {
            if (adl.isSet('eventquotecurrency')) {
                return adl.get('eventquotecurrency');
            } else if (adl.isSet('currency')) {
                return adl.get('currency');
            }

            return '-1';
        };
    }

    return new Extractors();
});

