define('data-enrichment/register-data-enrichment-hooks',[
    'data-enrichment/hooks/global'
], function() {
    'use strict';

    var hooks = arguments,
        i;

    function setupHooks(edap) {
        for (i = 0; i < hooks.length; i++) {
            hooks[i].setupHooks(edap);
        }
    }

    return {
        setupHooks: setupHooks
    };
});

