define('ua/listeners/search',['adlhelpers', 'ua/ua-wrapper'], function(ADL, ua) {
    'use strict';

    /**
     * EVENTS:
     *  search.submit
     *  dated.search.submit
     *  search.hit.click
     *  search.map.property.click
     *  search.map.zoom
     *  search.map.move
     *  typeahead.query
     */
    function setupListeners(edap) {
        edap.on('search.submit', function(data, setAsync, done) {
            var dataAdl = new ADL(data),
                label,
                opts = {};

            setAsync();

            // custom metric 3
            opts[ua.metricName(3)] = 1;

            label = dataAdl.isSetGet('searchterm') || dataAdl.isSetGet('geonode') || dataAdl.isSetGet('keyword');

            ua.sendEvent(
                'search',
                'dateless onsite search',
                label,
                undefined,
                opts,
                data
            ).then(done);
        });

        edap.on('dated.search.submit', function(data, setAsync, done) {
            var dataAdl = new ADL(data),
                label,
                opts = {};

            setAsync();

            // custom metric 5
            opts[ua.metricName(5)] = 1;

            label = dataAdl.isSetGet('searchterm') || dataAdl.isSetGet('geonode') || dataAdl.isSetGet('keyword');

            ua.sendEvent(
                'search',
                'dated onsite search',
                label,
                undefined,
                opts,
                data
            ).then(done);
        });

        edap.on('typeahead.query', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'typeahead',
                'typeahead query',
                data.matchtype,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('search.hit.click', function(data, setAsync, done) {
            var label;

            setAsync();

            label = data.actionlocation + ': ' + data.badges;

            ua.sendEvent(
                'search',
                'search hit click',
                label,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('search.map.property.click', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'pdp preview',
                'click',
                data.eventlistingids,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('search.map.zoom', function(data, setAsync, done) {
            var label;

            setAsync();

            label = 'zoom ' + (data.zoomtype);

            ua.sendEvent(
                'search',
                'map',
                label,
                undefined,
                undefined,
                data
            ).then(done);
        });

        edap.on('search.map.move', function(data, setAsync, done) {
            setAsync();

            ua.sendEvent(
                'search',
                'map',
                'move',
                undefined,
                undefined,
                data
            ).then(done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

