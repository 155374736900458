define('ua/register-ua-listeners',[
    'ua/listeners/cart',
    'ua/listeners/flsp',
    'ua/listeners/generic',
    'ua/listeners/identity',
    'ua/listeners/partner-conversion',
    'ua/listeners/ppb',
    'ua/listeners/quote',
    'ua/listeners/rm',
    'ua/listeners/search',
    'ua/listeners/component-interaction',
    'ua/listeners/social',
    'ua/listeners/software',
    'ua/listeners/traveler-activity',
    'ua/listeners/traveler-conversion',
    'ua/listeners/user'
], function() {
    'use strict';

    var listeners = arguments,
        i;

    function setupListeners(edap, ua) {
        for (i = 0; i < listeners.length; i++) {
            listeners[i].setupListeners(edap, ua);
        }
    }

    return {
        setupListeners: setupListeners
    };
});

